/* eslint-disable import/prefer-default-export */
import basicAxios from 'axios';
import Routes, {
  accountDetailHelpers,
  accountFundingHelpers,
  cdBumpUpHelpers,
  cdInterestPaymentsHelpers,
  cdRenewalHelpers,
  editTransferHelpers,
  isExistingAccountFlow,
  isNewAccountFlow,
  overdraftProtectionHelpers,
  QueryParams,
} from '../containers/routes/routes.constants';
import {
  CookieNames,
  DynamicYieldBaseUrls,
  PageCategories,
  PageTypes,
} from '../components/cms/dynamicYield.constants';
import type {
  EventName,
  EventProperties,
  PageCategory,
  PageType,
} from '../components/cms/dynamicYield.constants';
import getSearchParam from '../utilities/url';
import { ACCESS_TOKEN_STORAGE_KEY, isTokenAuthenticated } from '../utilities/authentication';

export const getPageType = (pathname: string): PageType => {
  switch (pathname) {
    case Routes.DASHBOARD:
    case Routes.HOME:
      return PageTypes.HOMEPAGE;
    case Routes.AUTH_CALLBACK:
    case Routes.LOGOUT:
    case Routes.WELCOME:
    case Routes.ATM_LOCATOR:
    case Routes.DY_HEADER:
      return PageTypes.OTHER;
    default:
      return PageTypes.CATEGORY;
  }
};

export const getPageCategories = (pathname: string): PageCategory[] => {
  const dynamicAOCategories = isTokenAuthenticated(ACCESS_TOKEN_STORAGE_KEY)
    ? [PageCategories.EAO]
    : [PageCategories.NAO];

  const StaticPathCategories = Object.freeze({
    [Routes.ADD_EXTERNAL_ACCOUNT_OPTIONS]: [PageCategories.ACCOUNT],
    [Routes.ADD_EXTERNAL_ACCOUNT_NUM]: [PageCategories.ACCOUNT],
    [Routes.ADD_EXTERNAL_YODLEE]: [PageCategories.ACCOUNT],
    [Routes.ADD_YODLEE_ACCOUNTS_SUCCESS]: [PageCategories.ACCOUNT],
    [Routes.CANCEL_APPLICATION]: dynamicAOCategories,
    [Routes.CONTACT_US_APPLICATION]: dynamicAOCategories,
    [Routes.CONFIRM_TRANSFER]: [PageCategories.TRANSFERS],
    [Routes.COMBINED_STATEMENTS]: [PageCategories.COMMUNICATION],
    [Routes.DOCUMENTS]: [PageCategories.COMMUNICATION],
    [Routes.EAO_ACCOUNT_PATCH]: [PageCategories.EAO],
    [Routes.EAO_ACCOUNT_CONFIRMATION]: [PageCategories.EAO],
    [Routes.EXPLORE_PRODUCTS]: [PageCategories.ACCOUNT],
    [Routes.EXTERNAL_ACCOUNTS]: [PageCategories.ACCOUNT],
    [Routes.VIEW_INBOX]: [PageCategories.COMMUNICATION],
    [Routes.NAO_ACCOUNT_PATCH]: [PageCategories.NAO],
    [Routes.NAO_ACCOUNT_CONFIRMATION]: [PageCategories.NAO],
    [Routes.APPLICATION_INIT_FAIL]: dynamicAOCategories,
    [Routes.NEW_MESSAGE]: [PageCategories.COMMUNICATION],
    [Routes.NEW_TRANSFER]: [PageCategories.TRANSFERS],
    [Routes.OLD_EAO]: [PageCategories.EAO],
    [Routes.OLD_NAO]: [PageCategories.NAO],
    [Routes.PENDING_APPLICATION]: dynamicAOCategories,
    [Routes.PROCESSING_APPLICATION]: dynamicAOCategories,
    [Routes.PROCESSING_APPLICATION_TRANSMIT]: dynamicAOCategories,
    [Routes.CONTACT_SUPPORT]: [PageCategories.COMMUNICATION],
    [Routes.PREFERENCES]: [PageCategories.COMMUNICATION],
    [Routes.ETIN_VERIFY_SELF]: [PageCategories.USER],
    [Routes.ETIN_CERTIFY_TAX]: [PageCategories.USER],
    [Routes.PROFILE]: [PageCategories.USER],
    [Routes.REGISTRATION_FAILED]: [PageCategories.USER],
    [Routes.REPLY_MESSAGE]: [PageCategories.COMMUNICATION],
    [Routes.SECURITY]: [PageCategories.USER],
    [Routes.STATEMENTS]: [PageCategories.COMMUNICATION],
    [Routes.TRANSFERS_DASHBOARD]: [PageCategories.TRANSFERS],
    [Routes.TRIAL_DEPOSITS]: [PageCategories.ACCOUNT],
    [Routes.TRIAL_DEPOSIT_INSTRUCTIONS]: [PageCategories.ACCOUNT, PageCategories.FUNDING],
    [Routes.ACCOUNT_VERIFICATION_FAILED]: [PageCategories.ACCOUNT, PageCategories.FUNDING],
    [Routes.MAIL_CHECK]: [PageCategories.ACCOUNT, PageCategories.FUNDING],
    [Routes.VERIFY_TRANSFER]: [PageCategories.TRANSFERS],
    [Routes.VERIFY_TRIAL_DEPOSITS]: [PageCategories.ACCOUNT],
    [Routes.OTP_INITIATE]: [PageCategories.USER],
    [Routes.OTP_INITIATE_TRANSMIT]: [PageCategories.USER],
    [Routes.OTP_INITIATE_TRANSMIT_EAO]: [PageCategories.USER],
    [Routes.OTP_VALIDATE]: [PageCategories.USER],
    [Routes.NAO_VERIFY_TRIAL_DEPOSITS]: [
      PageCategories.NAO,
      PageCategories.FUNDING,
      PageCategories.ACCOUNT,
    ],
    [Routes.EAO_VERIFY_TRIAL_DEPOSITS]: [
      PageCategories.EAO,
      PageCategories.FUNDING,
      PageCategories.ACCOUNT,
    ],
    [Routes.ACH_EXTERNAL_AGREEMENT_PDF]: [PageCategories.COMMUNICATION],
    [Routes.OVERDRAFT_PROTECTION_SUCCESS]: [PageCategories.ACCOUNT],
    [Routes.REMOVE_OVERDRAFT_PROTECTION_SUCCESS]: [PageCategories.ACCOUNT],
    [Routes.CANCEL_CARD]: [PageCategories.ACCOUNT],
    [Routes.REWARDS]: [PageCategories.USER],
    [Routes.ZELLE]: [PageCategories.TRANSFERS],
    [Routes.OFFERS]: [PageCategories.COMMUNICATION],
    [Routes.ALERTS]: [PageCategories.COMMUNICATION],
    [Routes.BENEFICIARIES]: [PageCategories.USER, PageCategories.ACCOUNT],
    [Routes.RATE_AND_TERMS]: [PageCategories.USER],
  });

  if (StaticPathCategories[pathname]) {
    return StaticPathCategories[pathname];
  }

  // Paths that use prefixes
  switch (true) {
    case accountDetailHelpers.isAccountDetailsUrl(pathname):
    case cdInterestPaymentsHelpers.isCdInterestPaymentsUrl(pathname):
    case cdRenewalHelpers.isCdRenewalFlow(pathname):
    case cdBumpUpHelpers.isCDBumpUpFlow(pathname):
    case overdraftProtectionHelpers.isOverdraftProtectionUrl(pathname):
      return [PageCategories.ACCOUNT];
    case isNewAccountFlow(pathname):
      return [PageCategories.NAO];
    case isExistingAccountFlow(pathname):
      return [PageCategories.EAO];
    case accountFundingHelpers.isDirectFunding(pathname):
      return [PageCategories.FUNDING];
    case accountFundingHelpers.isEAOFunding(pathname):
      return [PageCategories.EAO, PageCategories.FUNDING];
    case accountFundingHelpers.isNAOFunding(pathname):
      return [PageCategories.NAO, PageCategories.FUNDING];
    case editTransferHelpers.isEditTransfersUrl(pathname):
      return [PageCategories.TRANSFERS];
    default:
      return [];
  }
};

// https://www.w3schools.com/js/js_cookies.asp
function getCookie(cname: string): string | undefined {
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (const iterator of ca) {
    let c = iterator;
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return undefined;
}

type BaseRequest = {
  user: {
    dyid_server?: string;
    dyid?: string;
  };
  session: {
    dy?: string;
  };
};

type ChooseRequest = BaseRequest & {
  selector?: {
    groups?: string[];
    preview?: {
      ids: string[];
    };
  };
  context: {
    page: {
      type: PageType;
      location: string;
      data: PageCategory[]; // We decide the shape of this, will need to refine type later
    };
    device: {
      userAgent: string;
    };
  };
  options: {
    isImplicitPageview: boolean;
  };
};

export type Variation = {
  id?: string;
  image: string;
  cta: string;
  title: string;
  subtitle: string;
  link: string;
  decisionId: string;
  description?: string;
};

export type DYVariation = {
  decisionId?: string;
  name?: string;
  variations?: { payload: { data: Variation } }[];
};

export type ChooseResponse = {
  choices: {
    decisionId: string;
    variations?: {
      payload: {
        data: Variation;
      };
    }[];
    name?: string;
  }[];
  cookies: {
    name: string;
    value: string;
    maxAge: string;
  }[];
};

const getHeaders = () => ({
  'Content-Type': 'application/json',
  'DY-API-Key': window.__config__.DYNAMIC_YIELD_API_KEY,
});

type HasSucceededListener = (arg1: boolean) => void;

type ChooseRequestStatusTracker = {
  hasSucceeded: boolean;
  hasSucceededInternal: boolean;
  hasSucceededListeners: HasSucceededListener[];
  registerListener: (arg1: HasSucceededListener) => void;
  unregisterListener: (arg1: HasSucceededListener) => void;
};

/**
 * We need to make sure the the response from the first choose request comes back before we make
 * any other DY calls, so that those subsequent calls all use the dyid and session ID from the
 * initial choose response. To accomplish this, we use hasSucceeded to keep track of whether we've had a
 * successful choose response. Any other service functions will need to check for hasSucceeded already being
 * true, and if not they will need to register a listener so that they can wait until the value of hasSucceeded
 * has been updated before attempting to make any requests.
 */
export const chooseRequestStatusTracker: ChooseRequestStatusTracker = {
  // If we already have cookies when the app loads, we can treat is as if a choose request has already succeeded
  hasSucceededInternal: !!(
    getCookie(CookieNames.DYID) &&
    getCookie(CookieNames.DYID_SERVER) &&
    getCookie(CookieNames.DYJSESSION)
  ),
  hasSucceededListeners: [],

  set hasSucceeded(succeeded: boolean) {
    this.hasSucceededInternal = succeeded;
    this.hasSucceededListeners.forEach((listener) => {
      listener(succeeded);
    });
  },
  get hasSucceeded() {
    return this.hasSucceededInternal;
  },
  registerListener(listener: HasSucceededListener) {
    this.hasSucceededListeners.push(listener);
  },
  unregisterListener(listener: HasSucceededListener) {
    this.hasSucceededListeners = this.hasSucceededListeners.filter((l) => l !== listener);
  },
};

const constructRequestObject = (
  isImplicitPageview: boolean,
  campaignGroupName?: string[]
): ChooseRequest => {
  const user = {
    dyid_server: getCookie(CookieNames.DYID),
    dyid: getCookie(CookieNames.DYID),
  } as const;
  const session = { dy: getCookie(CookieNames.DYJSESSION) } as const;
  const previewParam = getSearchParam(window.location, QueryParams.dyApiPreview);
  // Only include the preview parameter for the calls where we're actually fetching a campaign
  const preview =
    previewParam && campaignGroupName && campaignGroupName.length
      ? { ids: [previewParam] }
      : undefined;

  const type = getPageType(window.location.pathname);
  const pageData = type === PageTypes.CATEGORY ? getPageCategories(window.location.pathname) : [];

  return {
    user,
    session,
    context: {
      page: {
        type,
        location: window.location.href,
        data: pageData,
      },
      device: {
        userAgent: window.navigator.userAgent,
      },
    },
    options: { isImplicitPageview },
    selector: {
      groups: campaignGroupName,
      preview,
    },
  };
};

type ResponseCookie = {
  name: string;
  value: string;
  maxAge: string;
};

const handleResponseCookies = (cookies: ResponseCookie[]) => {
  cookies.forEach(({ name, value, maxAge }) => {
    const cookieValue = `${name}=${value};Max-Age=${maxAge}`;
    document.cookie = cookieValue;
    if (name === CookieNames.DYID_SERVER) {
      // Send cookie to nginx server to be returned as a first-party cookie
      basicAxios.post(Routes.DY_HEADER);
    }
  });
};

const verifyCampaignAndRequestStatusTracker = (campaignNames: string[]) => {
  return campaignNames && !chooseRequestStatusTracker.hasSucceeded;
};

export const sendChooseRequest = async (
  isImplicitPageview: boolean,
  campaignGroupName?: string[]
): Promise<ChooseResponse | null | undefined> => {
  if (window.__config__.DYNAMIC_YIELD_ENABLED !== 'true' || !getCookie(CookieNames.DYID)) {
    return undefined;
  }

  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve) => {
    if (verifyCampaignAndRequestStatusTracker(campaignGroupName)) {
      const listener = (succeeded: boolean) => {
        if (succeeded) {
          resolve(sendChooseRequest(isImplicitPageview, campaignGroupName));
        }
        chooseRequestStatusTracker.unregisterListener(listener);
      };
      chooseRequestStatusTracker.registerListener(listener);
    } else {
      try {
        const response = await basicAxios.post(
          `${DynamicYieldBaseUrls.DIRECT_BASE_URL}/user/choose`,
          constructRequestObject(isImplicitPageview, campaignGroupName),
          { headers: getHeaders() }
        );

        if (response?.data?.cookies) {
          handleResponseCookies(response.data.cookies);
        }
        chooseRequestStatusTracker.hasSucceeded = true;
        resolve(response.data);
      } catch {
        // Don't update chooseRequestStatusTracker; if a previous request was successful that's all we need
        resolve(undefined);
        return;
      }
    }

    resolve(undefined);
  });
};

type Event<N extends EventName> = {
  name: N;
  properties?: EventProperties<N>;
};
type Engagement = {
  decisionId: string;
  type: string;
};

type EventRequest<N extends EventName> = BaseRequest & {
  events: Event<N>[];
};
type EngagementRequest = BaseRequest & {
  engagements: Engagement[];
};

export async function reportDynamicYieldEvent<N extends EventName>(
  name: N,
  properties: EventProperties<N>
): Promise<void> {
  if (window.__config__.DYNAMIC_YIELD_ENABLED !== 'true') {
    return;
  }
  const sendEventRequest = async () => {
    const user = {
      dyid_server: getCookie(CookieNames.DYID),
      dyid: getCookie(CookieNames.DYID),
    } as const;
    const session = { dy: getCookie(CookieNames.DYJSESSION) } as const;

    if (user.dyid_server && user.dyid && session) {
      try {
        await basicAxios.post<EventRequest<N>, undefined>(
          `${DynamicYieldBaseUrls.COLLECT_BASE_URL}/user/event`,
          {
            user,
            session,
            events: [{ name, properties }],
            url: window.location,
          },
          {
            headers: getHeaders(),
          }
        );
      } catch {
        // Nothing to do on an error, DY just won't count the event
      }
    }
  };

  // Choose request has not completed yet, so we need to wait to make sure we have dyid and dyjsession cookies
  if (!chooseRequestStatusTracker.hasSucceeded) {
    const listener = (succeeded: boolean) => {
      if (succeeded) {
        sendEventRequest();
      }
      chooseRequestStatusTracker.unregisterListener(listener);
    };
    chooseRequestStatusTracker.registerListener(listener);
  } else {
    sendEventRequest();
  }
}
export async function reportDynamicYieldEngagement(decisionId: string): Promise<void> {
  if (window.__config__.DYNAMIC_YIELD_ENABLED !== 'true') {
    return;
  }
  const user = {
    dyid_server: getCookie(CookieNames.DYID),
    dyid: getCookie(CookieNames.DYID),
  } as const;
  const session = { dy: getCookie(CookieNames.DYJSESSION) } as const;
  try {
    await basicAxios.post<EngagementRequest, undefined>(
      `${DynamicYieldBaseUrls.COLLECT_BASE_URL}/user/engagement`,
      {
        user,
        session,
        engagements: [
          {
            type: 'CLICK',
            decisionId,
          },
        ],
      },
      {
        headers: getHeaders(),
      }
    );
  } catch {
    // Nothing to do on an error, DY just won't count the event
  }

  //  Note: No need to check for Choose request has completed here, since this call is made after the choose request is succeeded
}
