import axios from '../../utilities/axios';
import {
  APPLICATIONS_CITIZENSHIPS_URL,
  APPLICATIONS_CUSTOMER_SEARCH_URL,
  APPLICATIONS_CUSTOMER_TAX_ID_URL,
  APPLICATIONS_JOINT_OWNERS_URL,
  APPLICATIONS_JOINT_OWNER_V2_URL,
  APPLICATIONS_STATES_URL,
  APPLICATIONS_URL,
  APPLICATIONS_V2_URL,
  BILLPAY_URL_INFO_URL,
  CUSTOMER_INFO_URL,
  PATCH_APPLICATIONS_URL,
  VALIDATE_APPLICATIONS_URL,
  ZELLE_URL_INFO_URL,
} from '../../utilities/route-mappings';
import { v2HeaderDataApplication } from '../../utilities/apiRequestHeader';
import type {
  CandidateValidateNewCustomerApplicationResponse,
  CustomerApplicationBody,
  CustomerSearchRequestBody,
  PartialCustomerApplicationBody,
  PendingApplicationsResponse,
  ValidateNewCustomerApplicationResponse,
  CustomerApplicationTransmitBody,
} from './applications.contracts.types';
import type {
  BillPayUrlInfo,
  CustomerInfo,
  PendingApplication,
  SubmitApplicationClientResponse,
  ZelleUrlInfo,
} from '../../utilities/types';
import type { PublicJWK } from '../../utilities/crypt';
import { mockKeyPair } from '../../utilities/crypt';
import worker from '../../workers/singletonWorker';
import { encodeBase64 } from '../../utilities/encodeOrDecode';

export const fetchPendingApplications = async (
  customerId: string
): Promise<PendingApplicationsResponse> => {
  const res = await axios.get(`${APPLICATIONS_URL()}?customerId=${customerId}`);
  return res.data;
};

const createFleKeyHeaderToDecryptAccountId = async () => {
  let key;
  try {
    key = await worker.getPublicKey();
  } catch (e) {
    key = mockKeyPair;
  }
  return {
    'fle-key': encodeBase64(JSON.stringify(key)),
  };
};

export const createApplicationService = async (
  application: CustomerApplicationBody
): Promise<SubmitApplicationClientResponse> => {
  const fleKeyHeader = await createFleKeyHeaderToDecryptAccountId();
  const response = await axios.post(APPLICATIONS_URL(), application, {
    headers: {
      ...fleKeyHeader,
    },
  });
  return response.data;
};

export const createApplicationTransmitService = async (
  application: CustomerApplicationTransmitBody
): Promise<SubmitApplicationClientResponse> => {
  const { headers } = v2HeaderDataApplication();

  let telemetryHeaders;
  if (window.bmak && typeof window.bmak.get_telemetry === 'function') {
    // Used to protect this endpoint from enumeration attacks
    telemetryHeaders = { 'BM-Telemetry': window.bmak.get_telemetry() };
  }

  const config = { headers: { ...headers, ...telemetryHeaders } };

  const response = await axios.post(APPLICATIONS_V2_URL(), application, config);
  return response.data;
};

export const patchApplicationService = async (
  patchApplicationData: PendingApplication
): Promise<SubmitApplicationClientResponse> => {
  const fleKeyHeader = await createFleKeyHeaderToDecryptAccountId();
  const response = await axios.patch(
    PATCH_APPLICATIONS_URL(patchApplicationData),
    {},
    { headers: { ...fleKeyHeader } }
  );
  return response.data;
};

export const validateApplicationService = async (
  application: PartialCustomerApplicationBody
): Promise<ValidateNewCustomerApplicationResponse> => {
  const response = await axios.post(VALIDATE_APPLICATIONS_URL(), application);
  return response.data;
};

export const candidateValidateApplicationService = async (
  application: PartialCustomerApplicationBody
): Promise<CandidateValidateNewCustomerApplicationResponse> => {
  const response = await axios.post(`${APPLICATIONS_URL()}?validate`, application);
  return response.data;
};

export const fetchCitizenshipList = () =>
  axios.get(APPLICATIONS_CITIZENSHIPS_URL()).then((res) => res.data);

// Per IBBNAO-811, this endpoint is being deprecated in favor of utilities endpoint
// that returns states without the X prefix
export const fetchStatesList = () => axios.get(APPLICATIONS_STATES_URL()).then((res) => res.data);

export const getCustomerInfoService = (): Promise<CustomerInfo> =>
  axios
    .get(`${CUSTOMER_INFO_URL()}?includeExternalAccountStatus=true`)
    .then((res) => res.data.data);

export const getTransmitCustomerInfoService = (key: PublicJWK): Promise<CustomerInfo> =>
  axios
    .get(`${CUSTOMER_INFO_URL()}?includeExternalAccountStatus=true&includeEncryptedSSN=true`, {
      headers: {
        'FLE-Key': encodeBase64(JSON.stringify(key)),
      },
    })
    .then((res) => res.data.data);

export const getZelleUrlService = (): Promise<ZelleUrlInfo> =>
  axios.get(ZELLE_URL_INFO_URL()).then((res) => res.data.data);

export const getBillPayUrlService = (): Promise<BillPayUrlInfo> =>
  axios.get(BILLPAY_URL_INFO_URL()).then((res) => res.data.data);

export const fetchJointOwnersService = () =>
  axios.get(APPLICATIONS_JOINT_OWNERS_URL()).then((res) => res.data);

export const fetchJointOwnersV2Service = () => {
  const { headers } = v2HeaderDataApplication();

  let telemetryHeaders;
  if (window.bmak && typeof window.bmak.get_telemetry === 'function') {
    // Used to protect this endpoint from enumeration attacks
    telemetryHeaders = { 'BM-Telemetry': window.bmak.get_telemetry() };
  }

  const config = { headers: { ...headers, ...telemetryHeaders } };
  return axios.get(APPLICATIONS_JOINT_OWNER_V2_URL(), config).then((res) => res.data);
};

export const SEARCH_STATUS_EXISTS = 'EXISTS';
export const SEARCH_STATUS_PENDING = 'PENDING';
export const SEARCH_STATUS_FORBIDDEN = 'FORBIDDEN';
export const SEARCH_STATUS_NEW = 'NEW';
export const SEARCH_STATUS_ERROR = 'ERROR';
export const SEARCH_STATUS_LOCKED = 'LOCKED';
export const SEARCH_STATUS_RETRY = 'RETRY';

export type CustomerSearchResponse =
  | {
      status: typeof SEARCH_STATUS_NEW | typeof SEARCH_STATUS_FORBIDDEN;
    }
  | {
      status: typeof SEARCH_STATUS_EXISTS;
      message: string;
      customer: {
        customerId: string;
        fullName: string;
      };
    }
  | {
      status:
        | typeof SEARCH_STATUS_ERROR
        | typeof SEARCH_STATUS_LOCKED
        | typeof SEARCH_STATUS_RETRY
        | typeof SEARCH_STATUS_PENDING;
      message: string;
    };

export type FetchCustomerIdType = (
  customerSearch: CustomerSearchRequestBody
) => Promise<CustomerSearchResponse>;

export const fetchCustomerIdService = (
  customerSearch: CustomerSearchRequestBody
): Promise<CustomerSearchResponse> => {
  let headers;
  if (window.bmak && typeof window.bmak.get_telemetry === 'function') {
    // Used to protect this endpoint from enumeration attacks
    headers = { 'BM-Telemetry': window.bmak.get_telemetry() };
  }

  return axios
    .get(APPLICATIONS_CUSTOMER_SEARCH_URL(), {
      params: customerSearch,
      headers,
    })
    .then((res) => res.data);
};

export type GetCustomerTaxIdResponse = {
  taxId: string;
};

export type FetchEncryptedCustomerTaxIdType = (key: PublicJWK) => Promise<GetCustomerTaxIdResponse>;

export type FetchCustomerInfoType = (key: PublicJWK) => Promise<CustomerInfo>;

export const fetchEncyptedCustomerTaxIdService = (
  key: PublicJWK
): Promise<GetCustomerTaxIdResponse> =>
  axios
    .get(APPLICATIONS_CUSTOMER_TAX_ID_URL(), {
      headers: { 'FLE-Key': encodeBase64(JSON.stringify(key)) },
    })
    .then((res) => res.data.data);
