const en = {
  accountAlerts: {
    alertsFor: 'Edit Email Alerts for',
    editHelperText: 'To enable an alert, check the adjacent box and enter a dollar amount.',
    enterValidDollarAmount: 'Please enter a dollar amount of $1 or more.',
  },
  accounts: {
    accountEndingIn: 'Account ending in',
  },
  accountVerified: {
    accountVerifiedCtaHeader: 'Account Verified',
    accountVerifiedCtaDescription: `Your external %bankName% account has now been linked to your %synchronyBankName% account. You may now make transfers and deposits to and from this account.`,
    trialDepositCtaHeader: 'Finish verifying your external account.',
    trialDepositFormDescription:
      'To finish verifying your external account, enter the two trial deposits below.',
    trialDepositFormTitle: 'Add a new external account',
  },
  actions: {
    saveChanges: 'Save Changes',
    add: 'Add',
    done: 'Done',
    showControlPanel: 'Dev Panel',
    resetAllFeatureFlags: 'Reset All Feature Flags',
  },
  alerts: {
    note: 'Note',
  },
  beneficiaries: {
    notAdded: 'You have not added beneficiaries to the following accounts:',
    selectOne: 'Please select at least one account for this beneficiary.',
  },
  bioCatchBrandName: {
    brandName: 'SYFBANK',
  },
  buttons: {
    editButton: 'Edit',
    removeButton: 'Remove',
    tryAgain: 'Try Again',
    hide: 'HIDE',
    reveal: 'REVEAL',
    bumpUpCD: 'Bump Up My CD',
  },
  cdRenewalNote: {
    termsNotAvailableNote:
      'Your current term is not available to select online. If you take no further action, your CD will renew at the current term on the date of maturity. If you wish to keep the current term for your CD but add or withdraw funds at maturity, you will need to call a Banker at 1-844-345-5790.',
  },
  cdReviewTile: {
    editRenewalAriaText: 'Edit Renewal Term Selection',
    editFundsAriaText: 'Edit Funds Selection',
  },
  dashboard: {
    ePreferences: 'ePreferences',
    myDashboard: 'My Dashboard',
  },
  dashboardGreeting: {
    hi: 'Hi',
    welcome: 'Welcome',
  },
  externalAccountManagement: {
    verifyButton: 'Verify Trial Deposits',
    reinitiateButton: 'Reinitiate Trial Deposits',
  },
  externalAccountAdd: {
    achCheckError: 'Please agree to the terms and conditions by checking the box to proceed.',
    achCheckLinkPre: 'I confirm that I am able to access and view the',
    achCheckLink: 'Authorization Agreement for Electronic (ACH) Debits and Credits',
    achCheckLinkPost: ', and I agree to its terms.',
  },
  generic: {
    dateFormat: 'MM/DD/YYYY',
    dateFormatAriaLabel: 'Date format must be MM/DD/YYYY',
  },
  fdicLogoContent: {
    content: 'FDIC-Insured - Backed by the full faith and credit of the U.S. Government',
    imageLabel: 'FDIC',
  },
  inputHelperTextConstants: {
    HELPER_TEXT_SUFFIX: '-helper-text',
    SCREEN_READER_LABEL_ICON: 'Icon',
  },
  loadMoreAccounts: {
    loadMoreAccounts: 'Load More Accounts',
    notSeeingAllAccounts: 'Not seeing all your accounts?',
    seeingAllAccounts: 'You’re seeing all of your accounts!',
  },
  newAccountConfirmationApplication: {
    CTA_HEADER: 'Your new account',
    NAO_CTA_COPY: `Your account has been created. All that's left is to set up your online access and add money to your new account.`,
    EAO_CTA_COPY:
      'Your account has been created. The last step is to add money to your new account.',
    CD_FUNDING_WARNING:
      'Remember, you’ll receive the quoted rate, if your funding deposit is received within the 15 days from account opening. If not, your CD will receive the APY/interest rate in effect on the day your funding deposit is processed (which may be lower than the rate offered when you opened the CD). The maturity date is based on the date the account is funded, this may differ from the date you received when you opened your CD.',
  },
  personalInformation: {
    dobInfoAge: 'Owners must be at least 18 years old.',
    encryptionNote: 'This site is secured with 128-bit encryption.',
  },
  personalInformationInlineErrorsMessage: {
    INVALID_LETTER_SPACE_ERROR_CHAR: `Sorry, %fieldName% can only include letters and spaces.`,
    INVALID_NUMBER_SPACE_ERROR_CHAR: `Sorry, %fieldName% can only include letters, numbers, and spaces.`,
    INVALID_AT_LEAST_ONE_SPACE_ERROR_CHAR: `Sorry, %fieldName% can only include letters, numbers, and spaces, and must include at least one space.`,
    INVALID_NAME_FIELD_ERROR_CHAR: `Sorry, %fieldName% can only include letters, spaces, apostrophes, and hyphens.`,
    INVALID_STREET_FIELD_ERROR_CHAR:
      'Sorry, Street Address can only include letters, numbers, certain characters and must include at least one space.',
    INVALID_UNIT_FIELD_ERROR_CHAR:
      'Sorry, Unit # can only include letters, numbers, certain characters and spaces.',
  },
  promoFundingConfirmation: {
    title: 'Is your deposit amount correct?',
    body: `The promotion's minimum required deposit amount is %promoMinDeposit%. If you continue, your deposit won't meet the minimum required to receive this promotional offer.`,
  },
  dynamicYieldCampaignGroup: {
    DASHBOARD_TILES: 'Dashboard Tiles',
    DASHBOARD_OVERLAY: 'Dashboard Overlay',
  },
  OTPRadioText: {
    SEND_CODE_TEXT_LABEL: 'Send code as a text message',
    SEND_CODE_TEXT_DESCRIPTION: 'Wireless and text message fees may apply from your carrier.',
    SEND_CODE_TEXT_VALUE: '1',
    SEND_CODE_CALL_LABEL: 'Send code as a phone call or voicemail',
    SEND_CODE_CALL_VALUE: '4',
  },
  sendOTPFieldName: {
    SEND_CODE_AS: 'sendCodeAs',
  },
  OTPText: {
    BACK_BUTTON_WARNING_TEXT:
      'Do not select the back button in your browser. This may cause an error.',
    OTP_TRUSTSCORE_HEADER: 'Confirm your identity',
    OTP_TRUSTSCORE_NO_PHONE_TEXT:
      'If you don’t have a phone number due to a hearing or speech disability, call us at ',
    OTP_TRUSTSCORE_CANCEL_TITLE: 'Entering a text-capable mobile number',
    OTP_TRUSTSCORE_DESCRIPTION:
      "We'll text you a one time code or verification link to the number below.",
    MOBILE_NUMBER: 'Mobile Number',
    ENTER_THE_CODE:
      'Please enter the code we sent you. The code has been sent to your phone number ending in ',
    SHOW_NUMBER: '%PhoneNumberLastFourDigit%. ',
    CODE_EXPIRY: 'The code will expire in 15 minutes. ',
  },
  OTPValidateText: {
    OTP_VALIDATE_HEADER_TRANSMIT: 'Confirm your identity',
    OTP_VALIDATE_HEADER_CANCEL: 'Confirming identity',
    OTP_VALIDATE_BODY_CANCEL: 'Confirming your identity',
    NOT_YOUR_PHONE_NUMBER: 'Not your phone number?',
    DONOT_HAVE_ANOTHER_NUMBER: 'I don’t have another phone number',
  },
  OTPVerifyFormLabels: {
    ENTER_THE_CODE: 'Enter the Code',
    ENTER_THE_CODE_CANCEL: 'Entering the Code',
    FIVE_DIGIT_CODE: '5-digit code',
  },
  TransmitAnalytics: {
    ALTERNATE_NUMBER: 'Please enter an alternate number',
    INCORRECT_CODE: 'The code you entered is incorrect. Please try again.',
  },
  TransmitStaticParams: {
    CHANNEL: 'WEB',
    LANGUAGE: 'EN',
    APPENV: window.__config__.TRANSMIT_APP_ENV,
  },
  TransmitCustomerType: {
    NEW: 'New',
    EXISTING: 'Existing',
  },
  TransmitFormLabels: {
    ENTER_PHONE_NUMBER: 'Phonenumber',
    ENTER_OTP_INPUT_NAME: 'enterOTPInput',
    ENTER_OTP_INPUT_FIELD_NAME: 'OTP',
  },
  ModalBtnText: {
    CANCEL_CONTINUE_BTN: 'Continue application',
    EXIT_BTN: 'Yes, Exit',
  },
  ModalText: {
    CANCEL_TRANSMIT_BODY:
      'You will not be able to complete the account opening process on the website and will need to call a Banker to proceed. Are you sure you want to cancel %modalBody%?',
    CANCEL_TRANSMIT_TITLE: 'Cancel %modalTitle%?',
  },
  ResponseDescription: {
    SUCCESS: 'Success',
    SYSTEM_ERROR: 'System Error / Application Error',
    FRAUD_DEVICE: 'Fraud device',
    USER_CANCELLED: 'User Cancelled /Opted Out',
    AUTH_FAILURE: 'Authentication Failure',
    SESSION_EXPIRED: 'Auth Session expired/Session expired',
    SOMETHING_WRONG: 'Something Went Wrong',
    INVALID_BSL_TOKEN: 'Invalid BSL Token',
    REVIEWED: 'Reviewed',
    DECLINED: 'Declined',
    EAO_MFA_STATUS: '',
  },
  security: {
    confirmNewPassword: 'Confirm New Password',
    lastFour: 'Your password must be different from your last four passwords.',
    newPassword: 'New Password',
    passwordCase: 'Includes both upper and lower case letters',
    passwordCharacters: 'Between 7-20 characters',
    passwordIncludesNumbers: 'Includes at least 2 numbers',
    passwordRequirementMet: 'Password requirement met',
    passwordRequirementNotMet: 'Password requirement not met',
    passwordRequirements: 'Password requirements',
    changePasswordNote:
      'All fields are required unless otherwise stated. Your password must be different from your last four passwords.',
  },
  CONTACT_US_MESSAGE: {
    CTA_HEADER: 'Contact us',
    CTA_COPY:
      'Thank you for your interest in opening an account with Synchrony Bank. At this time, we cannot proceed with your application online.',
    CTA_SUB_COPY:
      'Please call us in order to continue with your account opening at  %synchronyInfoPhone% and reference this code: %contactUsCode%.',
  },
  FORM_INSTRUCTION: {
    REQUIRED_FIELD_NOTE: 'Note: ',
    REQUIRED_FIELD_TEXT: 'All fields are required unless otherwise stated.',
    REQUIRED_FIELD_TEXT_AO:
      'Throughout this application, all fields are required unless otherwise stated.',
    REQUIRED_AMOUNT_TEXT: 'An amount is required.',
    EXISTING_JOINT_OWNER_LABEL: 'Add Existing Joint Owner',
    ADD_JOINT_OWNER_TEXT:
      'Select an existing joint owner to add to your account or add a new joint owner. Add at least 1 or up to %MAX_JOINT_OWNERS% joint owners to this account.',
  },
  REWARDS_TABLE: {
    HEADER1: 'Requirements',
    HEADER2: 'Perks',
    HOW_TO_QUALIFY: 'How to qualify',
  },
  REWARDS_OVERVIEW_TEXT: {
    HEADING: 'Let us reward you',
    SECTION1:
      "We appreciate your business and we want you saving with us for many years to come. So we've gone beyond great rates and outstanding customer service to create a reward program that gives you even more incentive to save.",
    SECTION2:
      'The Synchrony Bank Perks program not only rewards you for the balances you keep with us, but also for the length of time you have been a customer.',
    SECTION3:
      "You are automatically enrolled in Synchrony Bank Perks as soon as you open a deposit account with us. No signup is required and you don't have to keep track of points. Your perks, which start with deposit perks such as ATM reimbursements and expand to include exciting travel benefits, are based on your current balance or the length of time you have been a customer — whichever is greater. You are immediately eligible for all of the perks on your tier.",
    NOTE: 'Rewards are subject to change at any time, without any prior notice. You should not assume that any Rewards will remain available.',
  },
  MESSAGES: {
    newMessageTitle: 'Send a New Message',
    newMessageTopic:
      'To better assist you with account inquiry or request, please choose a topic below.',
    requiredFieldHelper: 'An asterisk (*) indicates a required field.',
    attachmentLimitError: `You cannot add more than ${window.__config__.MESSAGE_ATTACHMENT_LIMIT} attachments to a message. You will need to remove one or more attachments before sending this message.`,
    attachmentSizeError: 'This file exceeds the 5MB limit and cannot be attached.',
    genericAttachmentError: 'Something went wrong when attaching this file.',
    viewAttachmentError: 'Something went wrong when retrieving this file. Please try again.',
    messageRequiredError: 'Please enter a message',
    topicRequiredError: 'Please select a topic',
  },
  TRANSFERS: {
    maxDateHelperText: 'MM/DD/YYYY Please select a date not more than %maxDate% from today',
    newTransfer: 'New Transfer',
    verifiedAccounts: 'Verified Accounts',
    pendingAccounts: 'Pending Accounts',
    externalAccounts: 'External Accounts',
    achLimitButtonLink: 'Your external transfer limits',
    achLimitTitle: 'External Transfer Limits',
    achLimitBody: 'To or from non-Synchrony Bank Accounts',
    achLimitTableInbound: 'Inbound',
    achLimitTableOutbound: 'Outbound',
    achLimitTableDailyMax: 'Daily maximum',
    achLimitTableRolling: 'Rolling 30 days*',
    achLimitDisclaimer:
      'These limits are accurate as of %today% and are subject to change at any time. Any future-dated transfer that exceeds your limits will be cancelled.',
    achLimitsRollingDescriptor: `*Rolling transfer limits apply to all transfers made during the preceding 30 day period. For example, if your rolling limit is $100,000 and you've made transfers totaling $90,000 during the prior 30-days, you may transfer up to $10,000 today.`,
  },
  RATES_AND_TERMS: {
    readyToOpenNonIRAAccount: 'Ready to open a Non-IRA account?',
    lookingToOpenIRAAccount: 'Looking to open an IRA account?',
    contactBanker: 'Contact a Banker at ',
    toGetStarted: 'to get started.',
    productRatesIRA: 'To view rates for our IRA products, please visit our ',
    productIRALinkText: 'IRA Products Rates and Terms page.',
    productsNonIRAHeader: 'Non-IRA Product Rates and Terms',
    productsIRAHeader: 'IRA Product Rates and Terms',
    nonIRAHighYieldSavings: 'Non-IRA High Yield Savings',
    nonIRAMoneyMarket: 'Non-IRA Money Market',
    nonIRACertificateOfDeposit: 'Non-IRA Certificate of Deposit (CD)',
    affinityRatesHeader: 'Special Member Rates',
    depositAmount: 'Deposit Amount',
    disclosureText:
      'APYs are subject to change at any time without notice. Offers apply to personal non-IRA accounts only. Fees may reduce earnings. For Money Market and High Yield Savings Accounts, the rate may change after the account is opened. For CD accounts, a penalty may be imposed for early withdrawals. After maturity, if your CD rolls over, you will earn the offered rate of interest for your CD type in effect at that time.',
  },
  CERTIFICATE_OF_DEPOSITS: {
    interestRate: 'Interest Rate',
    currentAPY: 'Current APY*',
    interestPaidYTD: 'Interest Paid YTD',
    term: 'Term',
    bumpUpDate: 'Bump Up Date',
    maturityDate: 'Maturity Date',
  },
  ACCOUNT_LINKED_ERROR: {
    CTA_HEADER: 'Sorry, we were unable to connect your account.',
    CTA_COPY_YODLEE_ERROR:
      'We were not able to link your external account to Synchrony Bank at this time. Please try to link a different account. You may also mail a check to fund your account.',
    CTA_COPY:
      'The Bank account information cannot be verified through this method. Please try to connect this account using the Sign In method below or try to link a different account.',
    AFF_CTA_BTN_TEXT: 'View Account Dashboard',
    MAIL_A_CHECK: 'Mail a Check',
    MAIL_A_CHECK_TEXT:
      'To fund your new account, mail a check with your routing and account numbers visible.',
    HOW_TO_MAIL_A_CHECK: 'HOW TO MAIL A CHECK',
    SIGN_IN_EXTERNAL_ACCOUNT: 'SIGN INTO YOUR EXTERNAL ACCOUNT',
    GO_TO_DASHBOARD: 'GO TO DASHBOARD',
    CANCEL_AND_GO_TO_DASHBOARD: 'CANCEL AND GO TO DASHBOARD',
    TRY_ANOTHER_ACCOUNT: 'Try Another Account',
    TRY_ANOTHER_ROUTER: 'You can try connecting another account via routing and account number',
  },
};

export default en;
